// Generated by Framer (de6f63d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["MWfJvkAen", "bI5g8qCAB"];

const serializationHash = "framer-Ujjhr"

const variantClassNames = {bI5g8qCAB: "framer-v-1a2zm3v", MWfJvkAen: "framer-v-1uxggj6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"logo desktop": "MWfJvkAen", "logo mobile": "bI5g8qCAB"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "MWfJvkAen"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "MWfJvkAen", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "bI5g8qCAB") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Ujjhr", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1uxggj6", className)} data-framer-name={"logo desktop"} layoutDependency={layoutDependency} layoutId={"MWfJvkAen"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({bI5g8qCAB: {"data-framer-name": "logo mobile"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO1RBU0EgT3JiaXRlciBUZXh0IEJvbGQ=", "--framer-font-family": "\"TASA Orbiter Text Bold\", \"TASA Orbiter Text Bold Placeholder\", sans-serif", "--framer-font-size": "320px", "--framer-letter-spacing": "-0.05em", "--framer-line-height": "0.7em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-213e3b65-2f72-458c-b2f1-9c03134b2351, rgb(255, 241, 224)))"}}>KRONOS</motion.p><motion.p style={{"--font-selector": "Q1VTVE9NO1RBU0EgT3JiaXRlciBUZXh0IEJvbGQ=", "--framer-font-family": "\"TASA Orbiter Text Bold\", \"TASA Orbiter Text Bold Placeholder\", sans-serif", "--framer-font-size": "320px", "--framer-letter-spacing": "-0.05em", "--framer-line-height": "0.7em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-2gxw0f, var(--token-213e3b65-2f72-458c-b2f1-9c03134b2351, rgb(255, 241, 224)))"}}>CLUB</motion.p></React.Fragment>} className={"framer-ud9go0"} fonts={["CUSTOM;TASA Orbiter Text Bold"]} layoutDependency={layoutDependency} layoutId={"hp7i70xwL"} style={{"--extracted-2gxw0f": "var(--token-213e3b65-2f72-458c-b2f1-9c03134b2351, rgb(255, 241, 224))", "--extracted-r6o4lv": "var(--token-213e3b65-2f72-458c-b2f1-9c03134b2351, rgb(255, 241, 224))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/>)}</motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Ujjhr [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Ujjhr .framer-gfq42g { display: block; }", ".framer-Ujjhr .framer-1uxggj6 { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-Ujjhr .framer-ud9go0 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Ujjhr .framer-1uxggj6 { gap: 0px; } .framer-Ujjhr .framer-1uxggj6 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-Ujjhr .framer-1uxggj6 > :first-child { margin-left: 0px; } .framer-Ujjhr .framer-1uxggj6 > :last-child { margin-right: 0px; } }", ".framer-Ujjhr.framer-v-1a2zm3v .framer-1uxggj6 { min-height: 168px; min-width: 437px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 448
 * @framerIntrinsicWidth 1166
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"bI5g8qCAB":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 */
const FramerhIOqIyd5p: React.ComponentType<Props> = withCSS(Component, css, "framer-Ujjhr") as typeof Component;
export default FramerhIOqIyd5p;

FramerhIOqIyd5p.displayName = "Cubo Logo Footer Copy";

FramerhIOqIyd5p.defaultProps = {height: 448, width: 1166};

addPropertyControls(FramerhIOqIyd5p, {variant: {options: ["MWfJvkAen", "bI5g8qCAB"], optionTitles: ["logo desktop", "logo mobile"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerhIOqIyd5p, [{family: "TASA Orbiter Text Bold", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/hIOqIyd5p:default", url: "https://framerusercontent.com/assets/P8EeK7e5JBt77DYWKesgoUzOyzc.otf"}, url: "https://framerusercontent.com/assets/P8EeK7e5JBt77DYWKesgoUzOyzc.otf"}])